<template>
    <div id="nav">
      <div v-if="loggedIn && openTrip == ''">
        <GrowBottomNavigation :options="options" :value="selected" />
      </div>
      <span v-if="!loggedIn">
      <router-link to="/login">Login</router-link> | 
      <router-link to="/register">Signup</router-link> 
      </span>
    </div>
</template>

<script>

// https://vue-bottom-navigation.herokuapp.com/guide/curved#installation


import { GrowBottomNavigation } from "bottom-navigation-vue";
export default {
  name:'mainmenu',
  components: { GrowBottomNavigation },

  data() {
    var selected = this.$route.path;
    // var openTrip = localStorage.getItem('opentrip');
    const options =  [
      { id: '/', icon: 'fa-solid fa-home', title: 'Dash', color: '#629460', path: '/'},
      { id: '/trips/my', icon: 'fa-solid fa-star', title: 'Mijn', color: '#88498f', path: '/trips/my' },
      { id: '/trips/open', icon: 'fa-solid fa-list', title: 'Pool', color: '#ff6d01' , path: '/trips/open'},
      { id: '/finance', icon: 'fa-solid fa-euro', title: 'Geld', color: '#2274a5' , path: '/finance'},
      { id: '/settings', icon: 'fa-solid fa-gear', title: 'Conf', color: '#f9db69' , path: '/settings'}
    ];
    return {selected, options}
  },
  computed: {
  openTrip() {
      var ot = localStorage.getItem('opentrip');
      return ot;
    }
  }
}
</script>